import { IglobalThis } from '@/types';

// Only fire the pixel and CAPI event for Social Media Cookies (MRA) categoryID (9)
export function sendFacebookEvent(eventName: string) {
	const customGlobalThis: IglobalThis = globalThis;
	const doNotTrackStates = ['CO', 'VA', 'CA', 'LA', 'MO', 'SD', 'ME', 'NY'];
	if (
		(!!customGlobalThis?.OptanonActiveGroups &&
			(customGlobalThis?.OptanonActiveGroups as string)?.indexOf(',9,') >= 0) ||
		// eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
		(navigator?.doNotTrack !== '1' &&
			// eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
			!doNotTrackStates.includes(customGlobalThis?.Optanon?.getGeolocationData()?.state))
	) {
		if (typeof customGlobalThis.fbq !== 'undefined') {
			const facebookEventId = createFacebookEventId();
			// eslint-disable-next-line @typescript-eslint/no-unsafe-call
			customGlobalThis.fbq('track', eventName, { fillerInfo: 'info' }, { eventID: facebookEventId });
		}
	}
}

function createFacebookEventId() {
	//Code for creating uuid from:
	//https://www.w3resource.com/javascript-exercises/javascript-math-exercise-23.php
	let dt = new Date().getTime();
	const uuid = 'xxxxxxxx-xxxx-xxxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
		const r = (dt + Math.random() * 16) % 16 | 0;
		dt = Math.floor(dt / 16);
		return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16);
	});
	return uuid;
}
