export const iSpotPixelTracking = (trans_id: string | null) => {
	let channel = '';

	if (trans_id) {
		if (trans_id.toLowerCase().includes('sem')) {
			channel = 'SEM';
		} else if (trans_id.toLowerCase().includes('pmax') || trans_id.toLowerCase().includes('display')) {
			channel = 'Display';
		} else if (trans_id.toLowerCase().includes('facebook')) {
			channel = 'Paid_Social';
		} else if (trans_id.toLowerCase().includes('youtube') || trans_id.toLowerCase().includes('video')) {
			channel = 'Video';
		} else {
			channel = 'Organic_Direct';
		}

		return `&customdata=channel_${channel}`;
	}

	return channel;
};
