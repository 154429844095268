// GA Data handling - Storing to Local Storage
export const storeGADataToLocalStorage = () => {
	const gclid = new URLSearchParams(window.location.search).get('gclid');
	const gclsrc = new URLSearchParams(window.location.search).get('gclsrc');
	const expiry = localStorage.getItem('cc-gclId-expiry');
	const newExpiry = (Date.now() + 90 * 24 * 60 * 60 * 1000).toString();

	if (!expiry || expiry !== newExpiry) {
		localStorage.setItem('cc-gclId-expiry', newExpiry);
	}

	if (!gclsrc || gclsrc.indexOf('aw') !== -1) {
		if (gclid && localStorage.getItem('cc-gclId') !== gclid) {
			localStorage.setItem('cc-gclId', gclid);
		}
	}
};
