import { useEffect } from 'react';

import { useSearchParams } from 'next/navigation';
import { setCookie } from 'cookies-next';

import { Maybe, AnalyticsContext } from '@/types/generated';
import { dataLayerConfig, sendFacebookEvent } from '@/utils';
import { useAppContext } from '@/context';
import { errorPageView } from '@/constants';
import { iSpotPixelTracking } from '@/utils/analytics/ispot-pixel';
import { checkGADataExpiry } from '@/utils/checkGADataExpiry';
import { storeGADataToLocalStorage } from '@/utils/storeGADataToLocalStorage';

export interface IPageView {
	error?: string;
	analyticsContext?: Maybe<AnalyticsContext>;
}

const PageView = ({ error = '', analyticsContext }: IPageView) => {
	const { config } = useAppContext();
	const { iSpotPixel } = config.features.analytics;
	const searchParams = useSearchParams();
	const utmParams = searchParams.toString();
	const ccTransId = searchParams.get('trans_id');
	const ccAppendChannel = iSpotPixelTracking(ccTransId);

	useEffect(() => {
		dataLayerConfig({
			event: analyticsContext?.event || 'page_view',
			brand: config.name || analyticsContext?.brand || '',
			location: '',
			location_status: '',
			page_referrer: globalThis?.document?.referrer || '',
			...(analyticsContext ? analyticsContext : {}),
			...(error ? errorPageView[error] : {}),
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (config.features.analytics.facebookPixel) {
			sendFacebookEvent('PageView');
		}

		if (config.name === 'ClearChoice') {
			if (utmParams) {
				if (ccTransId) {
					localStorage.setItem('ccTransId', ccTransId);
					setCookie('marketing_source', searchParams.toString(), {
						path: '/',
						maxAge: 30 * 24 * 60 * 60 * 1000, // 30 days in milliseconds
					});
				}
				if (!globalThis?.sessionStorage.getItem('CCUtmParams')) {
					globalThis?.sessionStorage?.setItem('CCUtmParams', utmParams);
				} else {
					globalThis.sessionStorage.CCUtmParams = utmParams;
				}
			}

			if (iSpotPixel) {
				const hasIspotPixelImgTag = document.getElementById('cc-ispot-pixel');
				if (hasIspotPixelImgTag) {
					//Update the existing iSpot src if it exists in the DOM
					hasIspotPixelImgTag.setAttribute(
						'src',
						`https://pt.ispot.tv/v2/TC-5423-1.gif?app=web&type=visit${ccAppendChannel}`
					);
				} else {
					//create the iSpot img tag if it does not exist in the dom
					const iSpotImgTag = document.createElement('img');
					iSpotImgTag.id = 'cc-ispot-pixel';
					iSpotImgTag.src = `https://pt.ispot.tv/v2/TC-5423-1.gif?app=web&type=visit${ccAppendChannel}`;
					iSpotImgTag.alt = '';
					iSpotImgTag.style.display = 'none';
					//add it to the DOM as a child to <body>
					document.body.appendChild(iSpotImgTag);
				}
			}

			checkGADataExpiry();
			storeGADataToLocalStorage();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return null;
};

export default PageView;
